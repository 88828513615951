import React from "react";

export const Services = (props) => {
  return (
    <div id="serv">
      <div className="container">
        <div className="row d-flex">
         
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Aproveite os benefícios que você mais espera e precisa!</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/team.png" className="img-responsive scale-x" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
