import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "react-router-dom";
import JsonData from "./data/data.json";

import "./App.css";
import Landing from "./components/landing";
import Formsend from "./components/formsend";
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import Message from "./components/message";
import Account from "./components/account";
import ForuJob from "./components/forujob";
import Foru from "./components/foru";
import ContratoPF from "./components/contratopf";
import ContratoPJ from "./components/contratopj";
import SecurityCiber from "./components/securityCiber";
import SecurityDados from "./components/securityDados";
import SecurityUses from "./components/securityUses";
import Ouvidoria from "./components/ouvidoria";


const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div id="rootApp">
    <BrowserRouter>
      <Navigation data={landingPageData.Contact}/>
      <Routes>
        <Route path="/" element={<Landing />} />
          <Route path="/workforus" element={<Formsend />} />
          <Route path="/message" element={<Message />} />
          <Route path="/account" element={<Account />} />
          <Route path="/forujob" element={<ForuJob />} />
          <Route path="/foru" element={<Foru />} />
          <Route path="/contratoPF" element={<ContratoPF />} />
          <Route path="/contratoPJ" element={<ContratoPJ />} />
          <Route path="/ciber-security" element={<SecurityCiber />} />
          <Route path="/dados-security" element={<SecurityDados />} />
          <Route path="/uses-security" element={<SecurityUses />} />
          <Route path="/ouvidoria" element={<Ouvidoria />} />
      </Routes>
       <Contact data={landingPageData.Contact}/>
    </BrowserRouter>
    </div>
  );
};

export default App;
